import request from 'api/requestUtils'
import { getInsuranceProvider } from 'lib/insurance/insuranceHelpers'
import { paths } from '@luxuryescapes/contract-svc-insurance'
import qs from 'qs'
import { bookingProtectionQuoteMap } from './mappers/insuranceMap'
import config from 'constants/config'
import { TRAVEL_PROTECTION_VALID_COUNTRIES } from 'constants/config/region'
import { countOccupants } from 'lib/offer/occupancyUtils'
import { isCustomisableInsuranceProduct } from 'lib/insurance/insuranceUtils'

type GetQuotePayload = paths['/api/insurance/quote']['post']['parameters']['body']['payload']
type GetQuotePayloadResponse = paths['/api/insurance/quote']['post']['responses']['200']['content']['application/json']
type ServerQuote = paths['/api/insurance/quote']['post']['responses']['200']['content']['application/json']['result']
type ServerQuoteProduct = ServerQuote['products'][0]

function serverQuoteToLocal(
  serverQuote: ServerQuote,
  serverProduct: ServerQuoteProduct,
  params: GetQuoteParams,
): App.InsuranceQuote {
  return {
    id: serverQuote.id,
    key: params.key,
    destinationCountries: serverQuote.destination_countries,
    endDate: serverQuote.trip_end_date,
    startDate: serverQuote.trip_start_date,
    currencyCode: serverQuote.currency,
    productId: serverProduct.product_id,
    total: serverProduct.total,
    luxPlusTotal: serverProduct.lux_plus_total,
    luxPlusSavings: serverProduct.lux_plus_total ? Math.max(serverProduct.total - serverProduct.lux_plus_total, 0) : 0,
    excess: serverProduct.excess_amount,
    pds: serverProduct._links.pds.href,
    travellerCount: serverQuote.traveller_count,
    totalPerPerson: serverProduct.total / serverQuote.traveller_count,
    luxPlusTotalPerPerson: serverProduct.lux_plus_total / serverQuote.traveller_count,
    ageLimit: serverProduct.age_limit,
    disclaimer: serverProduct.disclaimer,
    seniorsApplicable: serverProduct.seniors_applicable,
    numberOfSeniors: params.numberOfSeniors,
    coverageAmount: params.coverAmount,
    luxPlusDiscountPercentage: serverQuote.lux_plus_discount_percentage,
    luxPlusDiscountValueDisplayThreshold: serverQuote.lux_plus_discount_value_display_threshold,
    mobileAppOnlyPrice: serverProduct.mobile_price_total ?? 0,
    mobileAppOnlyPricePerPerson: serverProduct.mobile_price_total ? serverProduct.mobile_price_total / serverQuote.traveller_count : 0,
    mobileAppOnlySavings: Math.max((serverProduct.mobile_price_total ?? 0) - serverProduct.total, 0),
    policyQuotes: serverProduct.policy_quotes?.map((policyQuote) => ({
      policy: policyQuote.policy,
      price: policyQuote.price,
      luxPlusPrice: policyQuote.lux_plus_price,
      pricePerPerson: policyQuote.price / serverQuote.traveller_count,
      luxPlusPricePerPerson: policyQuote.lux_plus_price / serverQuote.traveller_count,
      mobileAppOnlyPricePerPerson: policyQuote.mobile_price ? policyQuote.mobile_price / serverQuote.traveller_count : 0,
      mobileAppOnlyPrice: policyQuote.mobile_price ?? 0,
      pds: policyQuote.pds,
    })),
  }
}

interface GetQuoteParams {
  currency: string;
  regionCode: string;
  destinationCountries: Array<string>;
  coverAmount: number;
  startDate: string;
  endDate: string;
  travellers: App.Occupants | Array<App.Occupants>;
  isMobileApp?: boolean;
  isSpoofed?: boolean;
  cartId?: string;
  isCruise?: boolean;
  numberOfSeniors?: number;
  type: App.InsuranceProductType;
  /**
   * List of policy ids - used to fetch parts of a custom quote
   */
  customPolicyIds?: Array<string>;
  isLuxPlusMember?: boolean;
  /**
   * The key used to fetch this quote
   */
  key: string;
}

export async function getInsuranceQuote(
  productId: string,
  params: GetQuoteParams,
) {
  const travellers = Array.isArray(params.travellers) ? countOccupants(params.travellers) : params.travellers
  const payload: GetQuotePayload = {
    product_id: productId,
    brand: config.BRAND as GetQuotePayload['brand'],
    provider: 'cover_genius',
    travellers_details: {
      number_of_adults: travellers.adults,
      number_of_children: travellers.children ?? 0,
      number_of_infants: travellers.infants ?? 0,
      number_of_seniors: params.numberOfSeniors ?? 0,
    },
    isCruise: params.isCruise,
    total_price: params.coverAmount,
    country_code: params.regionCode as GetQuotePayload['country_code'],
    currency: params.currency,
    destination_countries: params.destinationCountries as GetQuotePayload['destination_countries'],
    trip_start_date: params.startDate,
    trip_end_date: params.endDate,
    session_id: params.cartId,
    mobile_only_price: params.isMobileApp,
    policy_ids: params.customPolicyIds,
    metadata: {
      sales_channel: params.isSpoofed ? 'agent' : 'customer',
      is_lux_plus_member: !!params.isLuxPlusMember,
    },
  }

  const useTravelProtection = params.type === 'travel-insurance' && TRAVEL_PROTECTION_VALID_COUNTRIES.includes(params.regionCode)
  if (!useTravelProtection) {
    payload.insurance_type = params.type === 'travel-insurance' ? 'insurance' : 'cfmr'
    payload.includingCovid = true
  }

  const url = useTravelProtection ? '/api/insurance/travel-protection/quote' : '/api/insurance/quote'
  return request.post<GetQuotePayloadResponse, GetQuotePayload>(
    url,
    payload,
    { credentials: 'include' },
  ).then(response => {
    if (response.result) {
      const quoteProducts = response.result.products
      const productQuote = quoteProducts.find(product => product.product_id === productId)
      if (productQuote) {
        return serverQuoteToLocal(response.result, productQuote, params)
      }
    }
    throw new Error(`No quote found for product ${productId}`)
  })
}

function serverPolicyOptionToLocal(serverPolicyOption): App.InsuranceProductPolicyOption {
  return {
    policy: serverPolicyOption.policy,
    name: serverPolicyOption.name,
    keyBenefits: serverPolicyOption.key_benefits,
  }
}

function getProductProtectionType(serverProduct): App.InsuranceProtectionType {
  if (serverProduct.is_full_protection) {
    return 'full'
  } else if (isCustomisableInsuranceProduct(serverProduct.id)) {
    return 'customisable'
  }
  return 'standard'
}

function serverProductToLocal(serverProduct, type: App.InsuranceType): App.InsuranceProduct {
  return {
    id: serverProduct.id,
    name: serverProduct.name,
    description: serverProduct.description,
    benefits: serverProduct.items,
    documents: serverProduct.documents && {
      pds: serverProduct.documents.pds,
      emc: serverProduct.documents.emc,
      pp: serverProduct.documents.pp,
      apply: serverProduct.documents.apply,
      benefits: serverProduct.documents.benefits,
      ausResidency: serverProduct.documents.aus_residency,
    },
    protectionType: getProductProtectionType(serverProduct),
    keyBenefits: serverProduct.key_benefits,
    estimateBracket: serverProduct.estimateBracket,
    benefitsHeading: serverProduct.key_benefits_heading,
    seniorsApplicable: !!serverProduct.seniors_applicable,
    extraCoverageAvailable: !!serverProduct.is_full_protection,
    recommended: serverProduct.recommended,
    policyNames: serverProduct.policies ?? [],
    policyOptions: serverProduct.policy_options?.map(serverPolicyOptionToLocal) ?? [],
    ageLimit: serverProduct.age_limit,
    type,
  }
}

type GetInsuranceProductsResponse = paths['/api/insurance/products']['get']['responses']['200']['content']['application/json']
type GetInsuranceTravelProtectionProductsResponse = paths['/api/insurance/travel-protection/products']['get']['responses']['200']['content']['application/json']
type GetInsuranceCustomisedProductsResponse = paths['/api/insurance/customise-products']['get']['responses']['200']['content']['application/json']
type GetInsuranceCustomisedTravelProtectionProductsResponse = paths['/api/insurance/travel-protection/customise-products']['get']['responses']['200']['content']['application/json']

interface GetInsuranceProductsOptions {
  isDomestic?: boolean
  isCruise?: boolean
}

/**
 * Returns the correct request based on the insurance parameters given
 */
async function getInsuranceProductRequest(
  regionCode: string,
  type: App.InsuranceProductType,
  provider: string,
  options: GetInsuranceProductsOptions = {},
): Promise<{ serverProducts: Array<any>, insuranceType: App.InsuranceType }> {
  const useTravelProtection = TRAVEL_PROTECTION_VALID_COUNTRIES.includes(regionCode)
  if (type === 'booking-protection') {
    // is CFMR insurance (a booking protection product)
    const query: any = {
      provider,
      isDomestic: options.isDomestic,
      insuranceType: 'cfmr',
      isCruise: options.isCruise,
    }
    const queryString = qs.stringify(query)
    return request.get<GetInsuranceProductsResponse>(`/api/insurance/products?${queryString}`).then(response => {
      return {
        serverProducts: response.result,
        insuranceType: 'cfmr',
      }
    })
  } else if (useTravelProtection) {
    // is travel protection insurance
    if (options.isCruise) {
      // travel protection end points don't support passing insurance type yet
      // so don't add it to query
      const query: any = {
        provider,
        isDomestic: options.isDomestic,
        isCruise: true,
      }
      const queryString = qs.stringify(query)
      return request.get<GetInsuranceTravelProtectionProductsResponse>(`/api/insurance/travel-protection/products?${queryString}`).then(response => {
        return {
          serverProducts: response.result,
          insuranceType: 'travel_protection',
        }
      })
    } else {
      // This end point doesn't support 'isCruise'
      const query: any = {
        provider,
        isDomestic: options.isDomestic,
      }
      const queryString = qs.stringify(query)
      return request.get<GetInsuranceCustomisedTravelProtectionProductsResponse>(`/api/insurance/travel-protection/customise-products?${queryString}`).then(response => {
        return {
          serverProducts: response.result,
          insuranceType: 'travel_protection',
        }
      })
    }
  } else {
    // is plain insurance
    const query: any = {
      provider,
      isDomestic: options.isDomestic,
      isCruise: options.isCruise,
      includingCovid: true,
    }
    const queryString = qs.stringify(query)

    if (options.isCruise) {
      return request.get<GetInsuranceProductsResponse>(`/api/insurance/products?${queryString}`).then(response => {
        return {
          serverProducts: response.result,
          insuranceType: 'insurance',
        }
      })
    } else {
      return request.get<GetInsuranceCustomisedProductsResponse>(`/api/insurance/customise-products?${queryString}`).then(response => {
        return {
          serverProducts: response.result,
          insuranceType: 'insurance',
        }
      })
    }
  }
}

export async function getInsuranceProducts(
  regionCode: string,
  type: App.InsuranceProductType,
  options: GetInsuranceProductsOptions = {},
) {
  const provider = getInsuranceProvider(regionCode)
  if (provider) {
    const { serverProducts, insuranceType } = await getInsuranceProductRequest(regionCode, type, provider, options)
    return serverProducts.map(product => serverProductToLocal(product, insuranceType))
  }

  return Promise.resolve([])
}

export function getInsuranceProductsBySubscription(subscriptionId: string, regionCode: string) {
  const useTravelProtection = TRAVEL_PROTECTION_VALID_COUNTRIES.includes(regionCode)
  return request.get<App.ApiResponse<Array<unknown>>>(
    `/api/insurance/subscription/${subscriptionId}/products`, { credentials: 'include' },
  )
    .then(response => response.result.map(res => serverProductToLocal(
      res,
      useTravelProtection ? 'travel_protection' : 'insurance',
    )))
}

export function sendOptOutRequest(id: string, productsIds: Array<string>) {
  const optOutData = {
    product_ids: productsIds,
  }
  return request.post(`/api/insurance/quote/${id}/opt_out`, optOutData, { credentials: 'include' })
}

function mapUpdateQuote(serverQuote, updateData: UpdateQuoteParams): App.InsuranceUpdateQuote {
  return {
    subscriptionId: serverQuote.id,
    id: serverQuote.update_id,
    totalPriceDiff: serverQuote.total_price_diff,
    total: serverQuote.total_price,
    startDate: updateData.startDate,
    endDate: updateData.endDate,
    coverageAmount: updateData.coverageAmount,
    travellers: updateData.travellers,
  }
}

interface UpdateQuoteParams {
  startDate: string;
  endDate: string;
  subscriptionId: string;
  coverageAmount: number;
  travellers: Array<App.OrderInsuranceItemTraveller>;
}

export function getInsuranceUpdateQuote(
  orderId: string,
  updateData: UpdateQuoteParams,
): Promise<App.InsuranceUpdateQuote> {
  const data = {
    subscription_id: updateData.subscriptionId,
    trip_start_date: updateData.startDate,
    trip_end_date: updateData.endDate,
    cover_amount: updateData.coverageAmount,
    travellers_details: updateData.travellers.map(traveller => ({
      surname: traveller.lastName,
      first_name: traveller.firstName,
      age_range: traveller.ageRange,
    })),
  }

  return request.post<App.ApiResponse<any>, any>(
    `/api/orders/${orderId}/insurance/quote-upgrade`,
    data,
    { credentials: 'include' },
  ).then(response => mapUpdateQuote(response.result, updateData))
}

interface InsuranceUpdateParams {
  updateId: string;
  coverAmount: number;
  transactionKey: string;
  total: number;
}

export async function updateInsurance(
  orderId: string,
  updates: InsuranceUpdateParams,
): Promise<void> {
  await request.post(`/api/orders/${orderId}/insurance/upgrade`, updates, { credentials: 'include' })
}

export type RefundProtectQuoteRequest = paths['/api/insurance/refund-protect/quote']['post']['parameters']['body']['payload']
export type RefundProtectQuoteResponse = paths['/api/insurance/refund-protect/quote']['post']['responses']['200']['content']['application/json']
export type RefundProtectQuotePatchRequest = paths['/api/insurance/refund-protect/quote']['patch']['parameters']['body']['payload']

export async function getBookingProtectionQuote(payload: RefundProtectQuoteRequest): Promise<App.BookingProtectionQuote> {
  return request.post<RefundProtectQuoteResponse, RefundProtectQuoteRequest>('/api/insurance/refund-protect/quote', payload, { credentials: 'include' })
    .then(response => bookingProtectionQuoteMap(response.result))
}

export async function patchBookingProtectionQuote(payload: RefundProtectQuotePatchRequest): Promise<App.BookingProtectionQuote> {
  return request.patch<RefundProtectQuoteResponse, RefundProtectQuotePatchRequest>('/api/insurance/refund-protect/quote', payload, { credentials: 'include' })
    .then(response => bookingProtectionQuoteMap(response.result))
}
