import { RefundProtectQuoteResponse } from 'api/insurance'
import { sum } from 'lib/array/arrayUtils'

export function bookingProtectionQuoteMap(payload: RefundProtectQuoteResponse['result']): App.BookingProtectionQuote {
  return {
    quoteId: payload.quoteId,
    price: sum(payload.products, product => product.totalProductPrice ?? 0),
    mobileAppOnlyPrice: sum(payload.products, product => product.mobileTotalProductPrice ?? 0),
    luxPlusDiscountPercentage: payload.luxPlusDiscountPercentage,
    luxPlusDiscountValueDisplayThreshold: payload.luxPlusDiscountValueDisplayThreshold,
    luxPlusPrice: sum(payload.products, (product: App.BookingProtectionProduct) => product.luxPlusTotalProductPrice ?? 0),
    products: payload.products.map((product: App.BookingProtectionProduct) => ({
      bookingStage: product.bookingStage,
      code: product.code,
      content: product.content,
      currencyCode: product.currencyCode,
      features: product.features,
      offeringMethod: product.offeringMethod,
      optInLabel: product.optInLabel,
      optOutLabel: product.optOutLabel,
      productId: product.productId,
      productPrice: product.productPrice ?? 0,
      totalProductPrice: product.totalProductPrice ?? 0,
      title: product.title,
      tsAndCsUrl: product.tsAndCsUrl,
      mobileProductPrice: product.mobileProductPrice ?? 0,
      mobileTotalProductPrice: product.mobileTotalProductPrice ?? 0,
    })),
  }
}
